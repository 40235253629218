import $ from "jquery";
import Swal from "sweetalert2";

export default function (url, data, callback = null, dataCB = null) {
    $("input.has-error,select.has-error,textarea.has-error").removeClass("has-error");
    $.post(url, data).done(function (data) {
        // console.log(`Response HTTP : ${data}.`);
        //  console.log(data);
        if (typeof data.title !== "undefined" && typeof data.message !== "undefined" && typeof data.class !== "undefined") {
            //console.log(data.title,data.message,data.class);
            if (typeof data.field_error !== "undefined") $("#" + data.field_error).addClass("has-error");

            Swal.fire({ icon: data.class, title: data.title, text: data.message });
        }
        if (typeof data.feedback !== "undefined" && parseInt(data.feedback) === 1) {
            if (typeof data.modal !== "undefined") {
                $(document).find(".modal").modal("hide");
                if ($("#dynamic_ajax_container").length === 0) {
                    //it doesn't exist
                }
                let MODAL = $("#dynamic_ajax_container");
                if (MODAL.length === 0) {
                    $(document).find(body).append('<div id="dynamic_ajax_container"></div>');
                    MODAL = $("#dynamic_ajax_container");
                }

                MODAL.find(".modal").modal("hide");
                MODAL.html("");
                MODAL.html(data.modal);
                MODAL.find(".modal").modal("show");

                MODAL.find(".modal").on("hidden.bs.modal", function () {
                    //console.log("closing modal");
                });
            } else if (typeof data.action !== "undefined" && data.action === "reload") {
                window.location.reload();
            } else if (typeof data.action !== "undefined" && data.action === "redirect" && typeof data.redirect !== "undefined") {
                window.location.href = data.redirect;
            }
        }
        if (callback !== null && typeof data.action !== "undefined" && data.action === "callback") {
            if (dataCB !== null) {
                callback(dataCB);
            } else if (typeof data.actionData !== "undefined" && data.actionData !== null) {
                callback(data.actionData);
            } else {
                callback(data);
            }
        }
    });
    return false;
}
